import * as _util2 from "util";

var _util = "default" in _util2 ? _util2.default : _util2;

import * as _websocketDriver2 from "websocket-driver";

var _websocketDriver = "default" in _websocketDriver2 ? _websocketDriver2.default : _websocketDriver2;

import _api from "./websocket/api";
import _client from "./websocket/client";
import _eventsource from "./eventsource";
import _process from "process";
var exports = {};
var process = _process;
var util = _util,
    driver = _websocketDriver,
    API = _api;

var WebSocket = function (request, socket, body, protocols, options) {
  options = options || {};
  this._stream = socket;
  this._driver = driver.http(request, {
    maxLength: options.maxLength,
    protocols: protocols
  });
  var self = this;
  if (!this._stream || !this._stream.writable) return;
  if (!this._stream.readable) return this._stream.end();

  var catchup = function () {
    self._stream.removeListener("data", catchup);
  };

  this._stream.on("data", catchup);

  API.call(this, options);
  process.nextTick(function () {
    self._driver.start();

    self._driver.io.write(body);
  });
};

util.inherits(WebSocket, API);

WebSocket.isWebSocket = function (request) {
  return driver.isWebSocket(request);
};

WebSocket.validateOptions = function (options, validKeys) {
  driver.validateOptions(options, validKeys);
};

WebSocket.WebSocket = WebSocket;
WebSocket.Client = _client;
WebSocket.EventSource = _eventsource;
exports = WebSocket;
export default exports;