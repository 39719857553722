import _event from "./event";
var exports = {};
var Event = _event;
var EventTarget = {
  onopen: null,
  onmessage: null,
  onerror: null,
  onclose: null,
  addEventListener: function (eventType, listener, useCapture) {
    this.on(eventType, listener);
  },
  removeEventListener: function (eventType, listener, useCapture) {
    this.removeListener(eventType, listener);
  },
  dispatchEvent: function (event) {
    event.target = event.currentTarget = this;
    event.eventPhase = Event.AT_TARGET;
    if (this["on" + event.type]) this["on" + event.type](event);
    this.emit(event.type, event);
  }
};
exports = EventTarget;
export default exports;