import * as _util2 from "util";

var _util = "default" in _util2 ? _util2.default : _util2;

import * as _net2 from "net";

var _net = "default" in _net2 ? _net2.default : _net2;

import * as _tls2 from "tls";

var _tls = "default" in _tls2 ? _tls2.default : _tls2;

import * as _url3 from "url";

var _url2 = "default" in _url3 ? _url3.default : _url3;

import * as _websocketDriver2 from "websocket-driver";

var _websocketDriver = "default" in _websocketDriver2 ? _websocketDriver2.default : _websocketDriver2;

import _api from "./api";
import _event from "./api/event";
var exports = {};
var util = _util,
    net = _net,
    tls = _tls,
    url = _url2,
    driver = _websocketDriver,
    API = _api,
    Event = _event;
var DEFAULT_PORTS = {
  "http:": 80,
  "https:": 443,
  "ws:": 80,
  "wss:": 443
},
    SECURE_PROTOCOLS = ["https:", "wss:"];

var Client = function (_url, protocols, options) {
  options = options || {};
  this.url = _url;
  this._driver = driver.client(this.url, {
    maxLength: options.maxLength,
    protocols: protocols
  });
  ["open", "error"].forEach(function (event) {
    this._driver.on(event, function () {
      self.headers = self._driver.headers;
      self.statusCode = self._driver.statusCode;
    });
  }, this);

  var proxy = options.proxy || {},
      endpoint = url.parse(proxy.origin || this.url),
      port = endpoint.port || DEFAULT_PORTS[endpoint.protocol],
      secure = SECURE_PROTOCOLS.indexOf(endpoint.protocol) >= 0,
      onConnect = function () {
    self._onConnect();
  },
      netOptions = options.net || {},
      originTLS = options.tls || {},
      socketTLS = proxy.origin ? proxy.tls || {} : originTLS,
      self = this;

  netOptions.host = socketTLS.host = endpoint.hostname;
  netOptions.port = socketTLS.port = port;
  originTLS.ca = originTLS.ca || options.ca;
  socketTLS.servername = socketTLS.servername || endpoint.hostname;
  this._stream = secure ? tls.connect(socketTLS, onConnect) : net.connect(netOptions, onConnect);
  if (proxy.origin) this._configureProxy(proxy, originTLS);
  API.call(this, options);
};

util.inherits(Client, API);

Client.prototype._onConnect = function () {
  var worker = this._proxy || this._driver;
  worker.start();
};

Client.prototype._configureProxy = function (proxy, originTLS) {
  var uri = url.parse(this.url),
      secure = SECURE_PROTOCOLS.indexOf(uri.protocol) >= 0,
      self = this,
      name;
  this._proxy = this._driver.proxy(proxy.origin);

  if (proxy.headers) {
    for (name in proxy.headers) this._proxy.setHeader(name, proxy.headers[name]);
  }

  this._proxy.pipe(this._stream, {
    end: false
  });

  this._stream.pipe(this._proxy);

  this._proxy.on("connect", function () {
    if (secure) {
      var options = {
        socket: self._stream,
        servername: uri.hostname
      };

      for (name in originTLS) options[name] = originTLS[name];

      self._stream = tls.connect(options);

      self._configureStream();
    }

    self._driver.io.pipe(self._stream);

    self._stream.pipe(self._driver.io);

    self._driver.start();
  });

  this._proxy.on("error", function (error) {
    self._driver.emit("error", error);
  });
};

exports = Client;
export default exports;