var exports = {};

var Event = function (eventType, options) {
  this.type = eventType;

  for (var key in options) this[key] = options[key];
};

Event.prototype.initEvent = function (eventType, canBubble, cancelable) {
  this.type = eventType;
  this.bubbles = canBubble;
  this.cancelable = cancelable;
};

Event.prototype.stopPropagation = function () {};

Event.prototype.preventDefault = function () {};

Event.CAPTURING_PHASE = 1;
Event.AT_TARGET = 2;
Event.BUBBLING_PHASE = 3;
exports = Event;
export default exports;